import Card from '@paperstac/design/lib/atoms/Card';
import CardContent from '@paperstac/design/lib/atoms/CardContent';
import CardHeader from '@paperstac/design/lib/atoms/CardHeader';
import NextButtonLink from '@paperstac/design/lib/atoms/NextButtonLink';
import UnstyledButton from '@paperstac/design/lib/atoms/UnstyledButton';
import Dropdown from '@paperstac/design/lib/molecules/Dropdown';
import NextLinkWithIcon from '@paperstac/design/lib/molecules/NextLinkWithIcon';
import CirclePlusIcon from '@paperstac/design/lib/particles/icons/CirclePlusIcon';
import HandshakeIcon from '@paperstac/design/lib/particles/icons/HandshakeIcon';
import LogoutIcon from '@paperstac/design/lib/particles/icons/LogoutIcon';
import MenuIcon from '@paperstac/design/lib/particles/icons/MenuIcon';
import ShieldCheckmarkIcon from '@paperstac/design/lib/particles/icons/ShieldCheckmarkIcon';
import { SxProp } from '@paperstac/design/lib/utilities/sx';
import { LOGIN, LOGOUT, REGISTER } from '@paperstac/routes/lib/authRoutes';
import { CLOSINGS, CREATE_CLOSING, MANAGE_IDENTITY } from '@paperstac/routes/lib/noteclosingsRoutes';
import React, { MouseEventHandler } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import { useCurrentAccount } from './CurrentAccountsProvider';

type TriggerComponentProps = SxProp & {
  onClick: MouseEventHandler<HTMLButtonElement>;
  size?: number;
};

const TriggerComponent = React.memo<TriggerComponentProps>(({ onClick, size, sx }) => (
  <UnstyledButton onClick={onClick} sx={{ '&:hover': { color: 'primary' }, ...sx }}>
    <MenuIcon size={size} />
  </UnstyledButton>
));
TriggerComponent.displayName = 'TriggerComponent';

const HeaderDropdownMenu = () => {
  const currentAccount = useCurrentAccount();
  const [authUser] = useAuthState(auth);

  const sxProps = {
    padding: '8px 16px',
  };

  const dropdownExtraProps = React.useMemo(() => {
    return authUser ? {} : { sx: { 'text-align': 'center' } };
  }, [authUser]);

  const headerTitle = React.useMemo(() => {
    return currentAccount ? `Hi, ${currentAccount.displayName}` : 'Get Started';
  }, [currentAccount]);

  const bodyContent = React.useMemo(() => {
    return authUser ? (
      <>
        <NextLinkWithIcon
          sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', cursor: 'pointer' }}
          icon={<HandshakeIcon />}
          href={CLOSINGS}
        >
          My Closings
        </NextLinkWithIcon>
      </>
    ) : (
      <NextButtonLink buttonLinkProps={{ sx: { width: '100%' } }} href={LOGIN}>
        Login
      </NextButtonLink>
    );
  }, [authUser]);

  const footerContent = React.useMemo(() => {
    return authUser ? (
      <NextLinkWithIcon
        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', cursor: 'pointer' }}
        icon={<LogoutIcon />}
        href={LOGOUT}
      >
        Logout
      </NextLinkWithIcon>
    ) : (
      <NextButtonLink buttonLinkProps={{ sx: { width: '100%', backgroundColor: 'primary' } }} href={REGISTER}>
        Register
      </NextButtonLink>
    );
  }, [authUser]);

  return (
    <Dropdown TriggerComponent={TriggerComponent} {...dropdownExtraProps}>
      <Card sx={{ position: 'absolute', right: 0, whiteSpace: 'nowrap', ...(!authUser && { width: '200px' }) }}>
        <CardHeader title={headerTitle} borderBottom sx={{ padding: '12px 16px' }} />
        <CardContent borderBottom sx={sxProps}>
          {bodyContent}
        </CardContent>
        <CardContent sx={sxProps}>{footerContent}</CardContent>
      </Card>
    </Dropdown>
  );
};

HeaderDropdownMenu.displayName = 'HeaderDropdownMenu';
export default React.memo(HeaderDropdownMenu);
