import styled from 'styled-components';
import sx from '../../utilities/sx';
var Button = styled.button.attrs(function (props) {
  return {
    type: props.type || 'button',
    variant: props.variant || 'default'
  };
}).withConfig({
  displayName: "Button",
  componentId: "sc-rs3bev-0"
})(["appearance:none;display:", ";width:", ";text-align:center;text-decoration:none;font-size:", ";line-height:", ";", ";font-weight:", ";padding:", ";border:0;background-color:", ";border-radius:", ";position:relative;&&{color:", ";&:hover,&:focus{color:", ";text-decoration:none;}}", " &:hover,&:active{", " ", "}&&:disabled{background-color:", ";color:", ";}", ""], function (props) {
  return props.block ? 'block' : 'inline-block';
}, function (props) {
  return props.block && '100%';
}, function (props) {
  return props.theme.fontSizes.body;
}, function (props) {
  return props.round ? '36px' : props.theme.lineHeights.condensed;
}, function (props) {
  return props.round && 'width: 36px;';
}, function (props) {
  return props.theme.fontWeights.bold;
}, function (props) {
  return props.round ? 0 : "".concat(props.theme.space[2], " ").concat(props.theme.space[3]);
}, function (props) {
  return props.theme.colors.button[props.variant].bg;
}, function (props) {
  return props.round ? props.theme.radii.round : props.theme.radii[1];
}, function (props) {
  return props.theme.colors.button[props.variant].text;
}, function (props) {
  return props.theme.colors.button[props.variant].textHover;
}, function (props) {
  return props.busy && "\n    &&& { color: rgba(0,0,0,0); }\n    &::after {\n      content: \"\";\n      position: absolute;\n      width: 20px;\n      height: 20px;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      margin: auto;\n      border: 3px solid ".concat(props.theme.colors.button[props.variant].text, ";\n      border-top-color: transparent;\n      border-radius: 50%;\n      animation: button-busy 1s linear infinite;\n    }\n  ");
}, function (props) {
  return !props.busy && "background-color: ".concat(props.theme.colors.button[props.variant].bgHover, ";");
}, function (props) {
  return !props.busy && "color: ".concat(props.theme.colors.button[props.variant].textHover, ";");
}, function (props) {
  return props.theme.colors.button[props.variant].bgDisabled;
}, function (props) {
  return props.theme.colors.button[props.variant].textDisabled;
}, sx);
export default Button;