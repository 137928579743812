import Box from '@paperstac/design/lib/atoms/Box';
import Button from '@paperstac/design/lib/atoms/Button';
import NextLink from '@paperstac/design/lib/atoms/NextLink';
import NoteClosingsLogo from '@paperstac/design/lib/atoms/NoteClosingsLogo';
import UIHeader from '@paperstac/design/lib/molecules/Header';
import { LOGIN, LOGOUT, REGISTER } from '@paperstac/routes/lib/authRoutes';
import { CLOSINGS, CREATE_CLOSING, HOME, MANAGE_IDENTITY } from '@paperstac/routes/lib/noteclosingsRoutes';
import { useRouter } from 'next/router';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import HeaderDropdownMenu from './HeaderDropdownMenu';

const Header = React.memo((props) => {
  const [authUser] = useAuthState(auth);
  const router = useRouter();
  const redirectTo = React.useMemo(() => {
    return (path: string) => {
      router.push(path);
    };
  }, [router]);
  const redirectToLogin = React.useMemo(() => () => redirectTo(LOGIN), [redirectTo]);
  const redirectToRegister = React.useMemo(() => () => redirectTo(REGISTER), [redirectTo]);

  return (
    <>
      <UIHeader
        mobileChildren={
          <>
            <Branding />
            <Box sx={{ ml: 4 }}>
              <HeaderDropdownMenu />
            </Box>
          </>
        }
        desktopChildren={
          <>
            <Branding />
            {!!authUser && (
              <Box sx={{ ml: 4 }}>
                <NextLink href={CLOSINGS} linkProps={{ variant: 'header' }}>
                  My Closings
                </NextLink>
              </Box>
            )}
            {!!authUser && (
              <Box sx={{ ml: 4 }}>
                <NextLink href={LOGOUT} linkProps={{ variant: 'header' }}>
                  Logout
                </NextLink>
              </Box>
            )}
            {!authUser && (
              <Box sx={{ ml: 4 }}>
                <Button onClick={redirectToLogin}>Login</Button>
              </Box>
            )}
            {!authUser && (
              <Box sx={{ ml: 4 }}>
                <Button onClick={redirectToRegister} variant="primary">
                  Register
                </Button>
              </Box>
            )}
          </>
        }
      />
    </>
  );
});

Header.displayName = 'Header';

export default Header;

const Branding = () => (
  <Box sx={{ flexGrow: 1 }}>
    <NextLink
      href={HOME}
      linkProps={{ sx: { fontWeight: 'bold', fontSize: 3, textDecoration: 'none', color: 'textLoud' } }}
    >
      <NoteClosingsLogo height={30} sx={{ display: 'inline-block' }} />
    </NextLink>
  </Box>
);
